<template>
  <q-page class="q-pa-lg maxWidth">
    <q-select
      v-model="form.operator"
      :label="$t('bus.ticket.operator')"
      emit-value
      map-options
      :options="operators"
    />
    <q-select
      v-model="form.area"
      :label="$t('bus.ticket.area')"
      :options="areas"
      :disable="areas.length === 0"
      :loading="loading"
      @input="val => form.zone = ''"
    />
    <div v-if="partner.slug === 'gohi'" class="need-help">
      {{ $t('tickets.need_help') }}<br>
      <a href="https://tiscon-maps-stagecoachbus.s3.amazonaws.com/ZoneMaps/North Scotland/Highlands Zone Map 2018.pdf" target="_blank">{{ $t('tickets.view_map') }}</a>
    </div>
    <q-select
      ref="zoneSelect"
      v-model="form.zone"
      :label="$t('bus.ticket.zone')"
      :options="zones"
      :disable="zones.length === 0"
      :loading="loading"
    />

    <q-select
      v-model="form.duration"
      :label="$t('bus.ticket.duration')"
      :options="durations"
      :disable="durations.length === 0"
      :loading="loading"
    />

    <q-select
      v-model="form.class"
      :label="$t('bus.ticket.class')"
      :options="classes"
    />
    <div v-if="total" class="text-right">
      <span> {{ $t('total_cost') }}: {{ total }} </span>
    </div>

    <m-unified-users v-model="people" />
    <div class="cta anti-padding">
      <m-guest-blocker v-if="hasRole('guest')" />
      <m-cta-button v-else class="buy-tickets" :disabled="filtered.length !== 1" @ctaClick="onSubmit">
        {{ $t('bus.ticket.buy') }}
      </m-cta-button>
    </div>
  </q-page>
</template>

<script>
import 'styles/ondemand.styl'
import { mapGetters } from 'vuex'
import { MUnifiedUsers, MGuestBlocker, MCtaButton } from 'components/'
import authentication from 'mixins/authentication'

export default {
  name: 'PublicTransportPurchase',
  components: { MUnifiedUsers, MGuestBlocker, MCtaButton },
  mixins: [authentication],
  data () {
    return {
      form: {
        operator: '',
        area: '',
        duration: '',
        class: '',
        zone: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner',
      tickets: 'publicTransport/tickets',
      user: 'userLookup',
      stash: 'ondemand/stash',
      loading: 'publicTransport/loading'
    }),
    operators () {
      switch (this.partner.slug) {
      case 'gohi': return [{ label: 'Stagecoach', value: 'stagecoach' }, { label: 'Bluebird', value: 'bluebird' }]
      case 'swift': return [{ label: 'National Express', value: 'nationalexpress' }]
      default: return [{ label: 'No operators', value: '' }]
      }
    },
    areas () {
      const areas = []
      this.tickets.forEach(ticket => {
        if (!areas.includes(ticket.area)) {
          areas.push(ticket.area)
        }
      })
      return areas
    },
    durations () {
      const days = []
      this.tickets.forEach(ticket => {
        if (!ticket.type.includes('group') && !ticket.type.includes('child')) {
          // For NatEx tickets, both Single and Dayrider tickets are 1-day tickets, so we assign a different value to keep track of singles
          const option = {
            label: `${ticket.days} day(s) (${ticket.type})`,
            value: ticket.type.includes('single') ? 0.5 : ticket.days
          }
          if (!days.some(day => day.label === option.label)) {
            days.push(option)
          }
        }
      })
      return days
    },
    classes () {
      switch (this.partner.slug) {
      default: return []
      case 'gohi': return ['Single', 'Group']
      case 'swift': return ['Adult', '18 and Under']
      }
    },
    filtered () {
      // Ticket list is dynamically generated so we want to eventually end up with a single ticket for the user to buy
      // Order of filters: Area -> Duration -> Zone (if applicable) -> Class
      const filteredByArea = this.tickets.filter(ticket => ticket.area === this.form.area)

      const filterByDuration = filteredByArea.filter(ticket => {
        if (ticket.type.includes('single')) return this.form.duration.value === 0.5
        return ticket.days === this.form.duration.value
      })

      const filterByZone = filterByDuration.filter(ticket => {
        return ticket.zone === this.form.zone
      })

      const classFilter = ticket => {
        switch (this.form.class) {
        case 'Single': return !ticket.type.includes('group')
        case 'Group': return ticket.type.includes('group')
        case 'Adult': return !ticket.type.includes('child')
        case '18 and Under': return ticket.type.includes('child')
        default: return false
        }
      }

      // Not every area has zones so we skip the zone filter if not

      const filterByClass = this.form.zone ? filterByZone.filter(classFilter) : filterByDuration.filter(classFilter)

      if (this.form.class) {
        // return filterByClass
        const sorted = filterByClass?.sort((a, b) => a.price - b.price)
        return [sorted?.[0]] || []
      }

      if (this.form.zone) {
        return filterByZone
      }
      if (this.form.duration) {
        return filterByDuration
      }
      return filteredByArea
    },
    zones () {
      const zones = []
      if (this.form.area) {
        this.tickets.forEach(ticket => {
          if (!zones.includes(ticket.zone) && ticket.zone && (ticket.area === this.form.area)) {
            zones.push(ticket.zone)
          }
        })
      }
      return zones
    },
    total () {
      if (this.filtered.length === 1) {
        return this.$n(this.filtered[0].price / 100, 'currency')
      } else {
        return 0
      }
    },
    people: {
      get () {
        return {
          traveller: this.stash.parameters.traveller,
          requester: this.stash.parameters.requester
        }
      },
      set (val) {
        this.$store.dispatch('ondemand/parameters', { requester: val.requester })
        this.$store.dispatch('ondemand/parameters', { traveller: val.traveller })
      }
    }
  },
  watch: {
    filtered: {
      immediate: true,
      handler (val) {
        if (val.length === 1) this.$store.dispatch('ondemand/stash', { ticket: this.filtered[0] })
      }
    },
    'form.operator' (val) {
      if (val) this.$store.dispatch('publicTransport/getTickets', val)
    }
  },
  created () {
    this.$store.dispatch('ondemand/parameters', { request: this.user, traveller: this.user })
  },
  methods: {
    onSubmit () {
      if (this.filtered.length !== 1) return
      this.$store.dispatch('ondemand/stash', { ticket: this.filtered[0] })
      this.$router.push({ name: 'ondemand-bus-summary' })
    }
  }

}
</script>

<style lang="stylus" scoped>
.maxWidth
  max-width 768px
  margin-inline auto
.anti-padding
  @media (max-width 600px)
    margin-inline -24px
.need-help
  margin 20px 0 10px
  font-size 90%
  a
    color var(--q-color-primary)
    font-weight 500
    text-decoration underline
</style>
